import React from 'react';
import styled from 'styled-components';
import {L} from '../../AppActions';

function Settings(props) {
  return (
    <Wrapper>
      <img
        src="/images/dash.svg"
        style={{width: 400, objectFit: 'contain', marginBottom: 20}}
      />
      <p>{L('please_choose_page')}</p>
      <img
        src="/images/arrow.png"
        style={{
          width: 130,
          objectFit: 'contain',
          paddingBottom: 100,
          alignSelf: 'flex-start',
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & > p {
    color: var(--primaryColor);
    font-weight: 450;
    letter-spacing: 1px;
    font-size: 18px;
    margin-bottom: 20px;
  }

  & > h1 {
    font-size: 32px;
  }
`;

export default Settings;
